<template>
  <div>
    <el-dialog title="修改单据" :visible.sync="isEditDialog" append-to-body>
      <div class="buyer_settilement_edit_dialog">
        <el-form
          ref="settlementRef"
          :rules="settlementRules"
          style="text-align: left"
          :model="settlement"
          label-width="160px"
          inline
          :validate-on-rule-change="false"
        >
          <el-form-item label="需方主体" prop="buyerOrgId" required>
            <el-select v-model="settlement.buyerOrgId" filterable clearable>
              <el-option
                v-for="item in buyerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结算单号" prop="sheetCode" required>
            <el-input v-model="settlement.sheetCode" clearable />
          </el-form-item>
          <!-- <el-form-item label="业务编号" prop="businessCode">
            <el-input v-model="settlement.businessCode"  clearable />
          </el-form-item> -->
          <el-form-item label="单据日期" prop="tradeTime" required>
            <el-date-picker
              v-model="settlement.tradeTime"
              type="date"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>

          <!-- <el-form-item label="开票类型" prop="billingType">
            <el-input v-model="settlement.billingType"  />
          </el-form-item> -->
          <el-form-item label="需方编码" prop="buyerCode" required>
            <el-input v-model="settlement.buyerCode" clearable />
          </el-form-item>
          <el-form-item label="需方主体名称" prop="buyerMainName" required>
            <el-input v-model="settlement.buyerMainName" clearable />
          </el-form-item>
          <!-- <el-form-item label="销售方名称" prop="sellerName">
            <el-input v-model="settlement.sellerName"  clearable />
          </el-form-item> -->
          <el-form-item label="销售方税号" prop="sellerTaxNo" required>
            <el-input v-model="settlement.sellerTaxNo" clearable />
          </el-form-item>

          <el-form-item label="供方编码" prop="sellerMainCode" required>
            <el-input v-model="settlement.sellerMainCode" clearable />
          </el-form-item>
          <el-form-item label="销方主体名称" prop="sellerMainName" required>
            <el-input v-model="settlement.sellerMainName" clearable />
          </el-form-item>
          <el-form-item label="销售方地址电话" prop="sellerAddressTel" required>
            <el-input v-model="settlement.sellerAddressTel" clearable />
          </el-form-item>

          <el-form-item
            label="销售方银行账户"
            prop="sellerBankAccount"
            required
          >
            <el-input v-model="settlement.sellerBankAccount" clearable />
          </el-form-item>
          <el-form-item label="合计金额" prop="amount" required>
            <el-input-number
              v-model="settlement.amount"
              :min="0"
              :controls="false"
              :precision="2"
              style="width: 200px"
              controls-position="right"
              @blur="handleIptNumber($event, 'amount')"
            />
          </el-form-item>
          <el-form-item label="合计税额" prop="taxAmount" required>
            <el-input-number
              v-model="settlement.taxAmount"
              :min="0"
              :controls="false"
              :precision="2"
              style="width: 200px"
              @blur="handleIptNumber($event, 'taxAmount')"
            />
          </el-form-item>

          <el-form-item label="价税合计" prop="sumAmount" required>
            <el-input-number
              v-model="settlement.sumAmount"
              :min="0"
              :controls="false"
              :precision="2"
              style="width: 200px"
              @blur="handleIptNumber($event, 'sumAmount')"
            />
          </el-form-item>
          <el-form-item label="折扣金额" prop="discountSumAmount" required>
            <el-input-number
              v-model="settlement.discountSumAmount"
              :min="0"
              :controls="false"
              :precision="2"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="回写地址" prop="writeBackUrl" required>
            <el-input v-model="settlement.writeBackUrl" clearable />
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <div style="padding: 20px 0">
            <el-button @click="isEditDialog = false">取消</el-button>
            <el-button type="primary" @click="submitsettlement('settlementRef')"
              >确认</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  reqUpdateSettlement,
  reqCreateSettlement,
} from "@/service/coo/trade.js";
export default {
  name: "SettlementUpdateTrade",
  components: {},
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() >= Date.now() + 0 * 24 * 3600 * 1000;
        },
      },
      buyerList: [],
      isEditDialog: false,
      settlementRules: {
        buyerOrgId: [{ required: true, message: "必填" }],
        sheetCode: [{ required: true, message: "必填" }],
        tradeTime: [{ required: true, message: "必填" }],
        buyerCode: [{ required: true, message: "必填" }],
        buyerMainName: [{ required: true, message: "必填" }],
        sellerTaxNo: [{ required: true, message: "必填" }],
        sellerMainCode: [{ required: true, message: "必填" }],
        sellerMainName: [{ required: true, message: "必填" }],
        sellerAddressTel: [{ required: true, message: "必填" }],
        sellerBankAccount: [{ required: true, message: "必填" }],
        amount: [{ required: true, message: "必填" }],
        taxAmount: [{ required: true, message: "必填" }],
        sumAmount: [{ required: true, message: "必填" }],
        discountSumAmount: [{ required: true, message: "必填" }],
        writeBackUrl: [{ required: true, message: "必填" }],
      },
      settlement: {
        id: undefined,
        sumAmount: 0,
        amount: 0,
        taxAmount: 0,
        discountSumAmount: 0,
        writeBackUrl: undefined,
        billingType: undefined,
        buyerCode: undefined,
        buyerMainName: undefined,
        sellerName: undefined,
        sellerTaxNo: undefined,
        sellerMainCode: undefined,
        sellerMainName: undefined,
        sellerAddressTel: undefined,
        sellerBankAccount: undefined,
        sheetCode: undefined,
      },
    };
  },
  created() {
    this.buyerList = this.$store.state.user.organizations;
  },
  mounted() {},
  methods: {
    submitsettlement(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.settlement.id) {
            const { success, message } = await reqUpdateSettlement(
              this.settlement
            );
            if (success) {
              this.toast(message, "success");
              this.isEditDialog = false;
              this.$emit("refreshPage");
            }
          } else {
            const { success, message } = await reqCreateSettlement(
              this.settlement
            );
            if (success) {
              this.toast(message, "success");
              this.isEditDialog = false;
              this.$emit("refreshPage");
            }
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    dialogVisible(row, t) {
      this.isEditDialog = t;
      this.settlement = row;
    },
    handleIptNumber() {
      this.settlement.sumAmount =
        this.settlement.amount + this.settlement.taxAmount;
    },
  },
};
</script>
<template>
  <el-dialog
    append-to-body
    title="回退订单"
    :visible.sync="visible"
    width="500px"
    :show-close="false"
  >
    <div class="cont">
      <el-form label-width="100px" v-model="backForm">
        <el-form-item label="结算单号：">
          <span>{{ settlement.sheetCode }}</span>
        </el-form-item>
        <el-form-item label="供方名称：">
          <span>{{ settlement.sellerMainName }}</span>
        </el-form-item>
        <el-form-item label="需方名称：">
          <span>{{ settlement.buyerMainName }}</span>
        </el-form-item>
        <el-form-item label="价税合计：">
          <span>{{ fmtAmount(settlement.sumAmount) }}</span>
        </el-form-item>
        <el-form-item label="结算阶段：">
          <el-select v-model="backForm.sheetStage" size="small">
            <el-option
              v-for="item in dictionary.tradeSheetStage"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="['BI', 'RI'].indexOf(item.value) == -1"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="cont-tips">
        <span>注：回退订单时会同步调用业务系统，请您谨慎操作！</span>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button type="default" :loading="loading" @click="handleCancelBack"
        >取消</el-button
      >
      <el-button type="primary" :loading="loading" @click="handleConfirmBack"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { fmtMoney } from "@/assets/js/format-util.js";
import { reqBackSettlement } from "@/service/coo/trade.js";

export default {
  name: "SettlementBackTrade",
  props: {
    dictionary: {
      type: Object,
      default() {
        return {
          tradeSheetStage: [],
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      visible: false,
      settlement: {},
      backForm: { id: undefined, sheetStage: "BI" },
    };
  },
  methods: {
    // 回退订单弹窗
    showDialog(trade) {
      console.log("showDialog:", trade);
      if (!trade || !trade.id || trade.id < 0) {
        this.toast("回退订单失败，没有指定订单主键！", "warning");
        return;
      }
      this.settlement = trade;
      this.visible = true;
    },
    // 确定回退订单
    async handleConfirmBack() {
      this.loading = true;
      this.backForm.id = this.settlement.id;
      const { success } = await reqBackSettlement(this.backForm);

      this.loading = false;
      if (success) {
        this.visible = false;
        this.toast("回退订单成功", "success");
        this.$emit("success");
      }
    },
    // 取消回退订单
    handleCancelBack() {
      this.visible = false;
    },
    // 格式订单金额
    fmtAmount(value) {
      return fmtMoney(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  padding: 30px 40px 30px 40px;
  text-align: left;

  .el-form-item {
    margin-bottom: 10px;
  }

  .cont-tips {
    padding-top: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}
</style>
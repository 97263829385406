<template>
  <el-dialog
    append-to-body
    title="合规检查"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
  >
    <div style="padding: 30px 50px 30px 50px; text-align: left">
      <el-form label-width="150px">
        <el-form-item v-if="trades.length == 1" label="确认结算单号：">
          <span style="font-weight: 500">{{ sheetCode }}</span>
        </el-form-item>
        <el-form-item v-else label="确认订单数量：">
          <span style="font-weight: 500"
            >共&nbsp;{{ trades.length }}&nbsp;条</span
          >
        </el-form-item>
        <br />
        <el-form-item label="重新匹配购方：">
          <el-radio v-model="checkParams.option.rematchBuyer" label="Y"
            >是</el-radio
          >
          <el-radio v-model="checkParams.option.rematchBuyer" label="N"
            >否</el-radio
          >
        </el-form-item>
        <el-form-item label="重新匹配销方：">
          <el-radio v-model="checkParams.option.rematchSeller" label="Y"
            >是</el-radio
          >
          <el-radio v-model="checkParams.option.rematchSeller" label="N"
            >否</el-radio
          >
        </el-form-item>
        <el-form-item label="重新匹配商品：">
          <el-radio v-model="checkParams.option.rematchMerchandise" label="Y"
            >是</el-radio
          >
          <el-radio v-model="checkParams.option.rematchMerchandise" label="N"
            >否</el-radio
          >
        </el-form-item>
        <el-form-item label="商品自动赋码：">
          <el-radio v-model="checkParams.option.merchandiseAutoCoding" label="Y"
            >是</el-radio
          >
          <el-radio v-model="checkParams.option.merchandiseAutoCoding" label="N"
            >否</el-radio
          >
        </el-form-item>
        <el-form-item label="重算订单明细：">
          <el-radio
            v-model="checkParams.option.recalculateTradeItemList"
            label="Y"
            >是</el-radio
          >
          <el-radio
            v-model="checkParams.option.recalculateTradeItemList"
            label="N"
            >否</el-radio
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button
        type="default"
        :disabled="loading"
        :loading="loading"
        @click="handleCancelCheck"
        >取消</el-button
      >
      <el-button
        type="primary"
        :disabled="loading"
        :loading="loading"
        @click="handleConfirmCheck"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { reqCheckSettlement } from "@/service/coo/trade.js";

export default {
  name: "SettlementCheckTrade",
  data() {
    return {
      visible: false,
      loading: false,
      checkParams: {
        option: {
          rematchBuyer: "Y",
          rematchSeller: "Y",
          rematchMerchandise: "Y",
          merchandiseAutoCoding: "Y",
          recalculateTradeItemList: "Y",
        },
        filter: {
          sheetIds: [],
        },
      },
      trades: [],
    };
  },
  computed: {
    sheetCode() {
      return this.trades.length == 1 ? this.trades[0].sheetCode : "";
    },
  },
  methods: {
    // 检查订单弹窗
    showDialog(trades) {
      if (trades.length < 1) {
        this.toast("请求检查失败，没有指定订单数据！", "warning");
        return;
      }

      if (
        trades.filter((trade) => {
          return trade.sheetStage != "BI";
        }).length > 0
      ) {
        this.toast("请求检查失败，包含已开票的订单！", "warning");
        return;
      }

      this.trades = trades;
      this.visible = true;
    },
    // 确认检查订单
    async handleConfirmCheck() {
      this.loading = true;
      this.checkParams.filter.sheetIds = this.trades.map((trade) => trade.id);
      const { success, message } = await reqCheckSettlement(this.checkParams);
      
      this.loading = false;
      if (success) {
        this.visible = false;
        this.toast(message, "success");
        this.$emit("success");
      }
    },
    // 取消检查订单
    handleCancelCheck() {
      this.visible = false;
    },
  },
};
</script>
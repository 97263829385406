<template>
  <el-dialog
    append-to-body
    title="修改票据张数"
    :visible.sync="visible"
    width="600px"
    :show-close="true"
  >
    <el-form
      ref="settlementForm"
      :model="settlement"
      :rules="settlementRules"
      class="edit-form"
      label-width="100px"
    >
      <el-form-item prop="ticketCount" label="票据张数：">
        <el-input-number
          v-model="settlement.ticketCount"
          :precision="0"
          :step="1"
          :min="0"
          :max="100"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="default" :loading="loading" @click="handleCancelUpdate"
        >取消</el-button
      >
      <el-button type="primary" :loading="loading" @click="handleConfirmUpdate"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { reqUpdateTicketCount } from "@/service/coo/trade.js";

export default {
  name: "SettlementEditTicketCount",
  data() {
    return {
      visible: false,
      settlement: {},

      settlementRules: {},
      loading: false,
    };
  },
  created() {},
  methods: {
    // 修改项目弹窗
    showDialog(trade) {
      if (!trade || !trade.id || trade.id < 0) {
        this.toast("修改失败，没有指定订单信息！", "warning");
        return;
      }
      if (trade.sheetStage == "CS") {
        this.toast("修改失败，不能修改已作废的订单！", "warning");
        return;
      }
      if (trade.sheetStage == "WJ") {
        this.toast("修改失败，不能修改已完结的订单！", "warning");
        return;
      }
      if (["DDCL", "CLSB"].indexOf(trade.sheetStatus) == -1) {
        this.toast("修改失败，此状态不允许修改票数！", "warning");
        return;
      }

      this.settlement = trade;
      this.visible = true;
    },

    // 处理取消修改
    handleCancelUpdate() {
      this.visible = false;
    },

    // 处理确认修改
    async handleConfirmUpdate() {
      const { success } = await reqUpdateTicketCount(this.settlement);
      if (success) {
        this.toast("修改成功，订单的票据张数已更新。", "success");
        this.$emit("success");
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-form {
  padding: 30px 30px 20px 30px;
  text-align: left;

  .el-form-item {
    margin-bottom: 22px;
  }

  ::v-deep .el-input {
    width: 100% !important;
  }

  .el-input-number {
    line-height: 30px;
    width: 150px;
  }
}
</style>

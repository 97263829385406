<template>
  <el-dialog
    append-to-body
    title="作废订单"
    :visible.sync="visible"
    width="500px"
    :show-close="false"
  >
    <div class="cont">
      <div class="cont-body">
        <label-span label="结算单号：" :span="settlement.sheetCode" />
        <label-span label="供方名称：" :span="settlement.sellerMainName" />
        <label-span label="需方名称：" :span="settlement.buyerMainName" />
        <label-span
          label="价税合计："
          :span="fmtAmount(settlement.sumAmount)"
        />
      </div>
      <div class="cont-tips">
        <span>注：作废订单时会同步调用业务系统，请您谨慎操作！</span>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button type="default" :loading="loading" @click="handleCancelRevoke"
        >取消</el-button
      >
      <el-button type="primary" :loading="loading" @click="handleConfirmRevoke"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { fmtMoney } from "@/assets/js/format-util.js";
import { reqRevokeSettlement } from "@/service/coo/trade.js";

export default {
  name: "SettlementRevokeTrade",
  data() {
    return {
      loading: false,
      visible: false,
      settlement: {},
    };
  },
  methods: {
    // 订单作废弹窗
    showDialog(trade) {
      if (!trade || !trade.id || trade.id < 0) {
        this.toast("作废订单失败，没有指定订单主键！", "warning");
        return;
      }
      this.settlement = trade;
      this.visible = true;
    },
    // 确定订单作废
    async handleConfirmRevoke() {
      this.loading = true;
      const { success } = await reqRevokeSettlement(this.settlement.id);

      this.loading = false;
      if (success) {
        this.visible = false;
        this.toast("作废订单成功", "success");
        this.$emit("success");
      }
    },
    // 取消订单作废
    handleCancelRevoke() {
      this.visible = false;
    },
    // 格式订单金额
    fmtAmount(value) {
      return fmtMoney(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  padding: 30px 40px 30px 40px;
  text-align: left;

  .cont-body {
    padding-bottom: 12px;

    .label-span {
      margin-bottom: 12px;
    }
  }

  .cont-tips {
    padding-top: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}
</style>
<template>
  <el-select
    v-model="model.sellerOrgId"
    :disabled="disabled"
    :filterable="filterable"
    :clearable="clearable"
    :remote="true"
    placeholder="关键字：编码/名称/简称"
    :remote-method="handleQuerySeller"
    reserve-keyword
    :loading="sellerLoading"
    multiple
    collapse-tags
  >
    <el-option
      v-for="item in sellerList"
      :disabled="item.contactMainId == null"
      :key="item.contactMainId + '-' + item.contactId"
      :value="item.contactMainId"
      :label="item.contactFullName"
    >
    </el-option>
  </el-select>
</template>

<script>
import { reqPagingSellerSelect } from "@/service/coo/seller.js";

export default {
  name: "SettlementSellerSelect",
  props: {
    model: {
      type: Object,
      default: () => {
        return { sellerOrgId: 0 };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sellerList: [],
      sellerLoading: false,
    };
  },
  created() {
    this.handleQuerySeller();
  },
  methods: {
    async handleQuerySeller(keyword) {
      // console.log("keyword：", keyword);
      if (keyword == "") {
        this.sellerList = [];
        return;
      }

      this.sellerLoading = true;
      const { success, data } = await reqPagingSellerSelect({
        keyword: keyword,
        pageSize: 10,
        pageNo: 1,
      });
      this.sellerLoading = false;
      if (success) {
        this.sellerList = data.list;
      }
      // console.log("sellerList：", this.sellerList);
    },
  },
};
</script>